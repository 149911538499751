<template>
  <div style="display:flex;justify-content:center;" :class="{ fullscreen: landing.loops.styling.Fullscreen, live: live }" :style="{ width: live ? '100%' : 'initial' }">
    <div :class="`device-container ${pageMode}`" :style="{ backgroundImage: `url(${landing.sections.styling.BackgroundImage})` }">
      <div class="device-container-inner" :style="deviceContainerInner">
        <div v-if="landing.sections.styling.BackgroundVideo" class="videoContainer">
          <iframe v-if="videoType === 'youtube'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe v-else-if="videoType === 'vimeo'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          <video v-else :src="videoSource" autoplay muted loop></video>
        </div>
        <div class="landing-widget" :style="landingWidgetStyle">
          <div ref="graphicContainer" class="loop-container" :style="landingContainerStyle">
            <div class="contentContainer" :style="contentContainerStyle">
              <div class="list-points-section">
                <div class="point-item">
                  <div class="point">
                    {{ stats.points }}
                  </div>
                  <div class="desc">
                    {{ landing.loops.main.HeaderLabel1 }}
                  </div>
                </div>
                <div class="point-item">
                  <div class="point">
                    {{ stats.referrals }}
                  </div>
                  <div class="desc">
                    {{ landing.loops.main.HeaderLabel2 }}
                  </div>
                </div>
                <div class="point-item">
                  <div class="point">
                    {{ stats.ranking }}
                  </div>
                  <div class="desc">
                    {{ landing.loops.main.HeaderLabel3 }}
                  </div>
                </div>
                <div class="point-item">
                  <div class="point">
                    {{ stats.rewards }}
                  </div>
                  <div class="desc">
                    {{ landing.loops.main.HeaderLabel4 }}
                  </div>
                </div>
              </div>
              <div class="main-section" :style="{ textAlign: landing.loops.main.Align }" @click="selectTab('main')">
                <h3 class="mx-4" :style="{ color: landing.loops.main.Color }">{{ landing.loops.main.Title.replace(/\[POINTS\]/, stats.points) }}</h3>
                <div :style="{ color: landing.loops.main.ContentColor, backgroundColor: landing.loops.main.ContentBackground,
                  display: 'inline-block', padding: '4px', borderRadius: '4px', margin: '10px 0px' }">
                  {{ landing.loops.main.Content }}
                </div>
                <p :style="{ color: landing.loops.main.Color }">{{ landing.loops.main.Description }}</p>
              </div>
              <div class="d-flex" :style="{ backgroundColor: landing.loops.points.BackgroundColor }">
                <div class="points-div col-md-6" :class="{ selectedDiv: pointsActive ,   unselectedDiv: rewardsActive }" @click="goto('points-div')">
                    <h3>{{ landing.loops.points.Title }}</h3>
                </div>
                <div class="rewards-div col-md-6" :class="{ unselectedDiv: pointsActive ,   selectedDiv: rewardsActive }" @click="goto('rewards-div')">
                    <h3>{{ landing.loops.rewards.Title }}</h3>
                </div>
              </div>
              <div class="reward-section" :style="{ backgroundColor: landing.loops.points.BackgroundColor }">
                <div ref='points-div' class="get-points" @click="selectTab('points')">
                  
                  <p>{{ landing.loops.points.Content }}</p>

                  <div class="link">
                    <input ref="shareLink" type="text" :value="shareLink" readonly>
                    <button @click="handleCopyLink">
                      <i class="uil uil-copy"></i>
                    </button>
                  </div>

                  <div class="list-actions">
                    <template v-if="live">
                      <a v-for="(item, index) in combined" :key="`action-${index}`" class="action-item" href="#"
                        :class="item.social"
                        :style="{ backgroundColor: item.itemBackground, color: item.itemColor }"
                        @click="handleClick(item)">
                        <div>
                          <i v-if="item.image && item.image.match(/^fa/)" :class="item.image" style="font-size: 27px;width:32px;text-align:center;vertical-align: middle;margin-right: 5px;" :style="{ color: item.itemColor }"></i>
                          <img v-else :src="item.image ? item.image : require(`@assets/images/socials/${item.action.match(/^Custom/) ? 'Url' : item.social}.png`)" style="width: 32px;height:32px;margin-right: 5px;"> 
                          {{ item.label }} 
                        </div>
                        <span class="point">
                          <i v-if="stats.actions[item.type]" class="uil uil-check"></i> <span v-else>{{ item.points }}</span>
                        </span>
                      </a>
                    </template>
                    <template v-else>
                      <draggable v-model="combined" >
                        <a v-for="(item, index) in combined" :key="`action-${index}`" class="action-item" href="#"
                          :class="item.social"
                          :style="{ backgroundColor: item.itemBackground, color: item.itemColor }">
                          <div>
                            <i v-if="item.image && item.image.match(/^fa/)" :class="item.image" style="font-size: 27px;width:32px;text-align:center;vertical-align: middle;margin-right: 5px;" :style="{ color: item.itemColor }"></i>
                            <img v-else :src="item.image ? item.image : require(`@assets/images/socials/${item.action.match(/^Custom/) ? 'Url' : item.social}.png`)" style="width: 32px;height:32px;margin-right: 5px;">
                            {{ item.label }} 
                          </div>
                          <span class="point">
                            {{ item.points }}
                          </span>
                        </a>
                      </draggable>
                    </template>
                  </div>
                </div>
                <div ref='rewards-div' class="rewards" @click="selectTab('rewards')">
                  

                  <div class="list-rewards mt-5">
                    <div v-for="reward in rewards" :key="`reward-${reward.id}`" class="reward-item"
                      :style="{ backgroundColor: landing.loops.rewards.ItemBackground, color: landing.loops.rewards.ItemColor }">
                      <div class="icon"
                        :style="{
                          backgroundColor: stats.actions[`Reward${reward.id}`] ? landing.loops.rewards.UnlockIconBackground : landing.loops.rewards.IconBackground,
                          color: stats.actions[`Reward${reward.id}`] ? landing.loops.rewards.UnlockIconColor : landing.loops.rewards.IconColor,
                        }">
                        <template v-if="stats.actions[`Reward${reward.id}`]">
                          <i class="uil uil-check"></i>
                        </template>
                        <template v-else>
                          <i class="uil" :class="{ 'uil-unlock-alt': stats.actions[`Reward${reward.id}`], 'uil-padlock': !stats.actions[`Reward${reward.id}`] }"></i>
                        </template>
                      </div>
                      <div class="content">
                        {{reward.name}}
                      </div>
                      <div class="unlock" :style="{ backgroundColor: stats.actions[`Reward${reward.id}`] ? landing.loops.rewards.TabBackground : landing.loops.rewards.UnlockTabBackground }">
                        <template v-if="stats.actions[`Reward${reward.id}`]">
                          {{ landing.loops.rewards.SuccessText }}
                        </template>
                        <template v-else>
                          {{ landing.loops.rewards.UnlockText.replace('[POINTS]', reward.points_unlock) }}
                        </template>
                      </div>
                      <template v-if="stats.actions[`Reward${reward.id}`]">
                        <h5 class="text-center" :style="{ color: landing.loops.rewards.ItemColor }">Unlocked</h5>
                      </template>
                      <template v-else>
                        <div class="progress-container">
                          <div class="progress-completed" :style="{ width: (stats.points / reward.points_unlock) * 100 + '%' }"></div>
                        </div>
                        <div class="progress-stat">{{ stats.points }}/{{reward.points_unlock}}</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="landing.poweredBy && landing.poweredBy.enabled" class="text-center mt-2">
              <span class="powered-by-content" :style="{ color: landing.sections.legalFooter.Color }">powered by <a :href="landing.poweredBy.url" target="_blank">{{ landing.poweredBy.name }} </a></span>
            </div>
            <div class="legal-container" :style="legalContainerStyle">
              <div>
                {{ landing.sections.legalFooter.Content }}
              </div>
              <template v-if="landing.sections.legalFooter.PolicyLabel || landing.sections.legalFooter.ServiceLabel">
                <a v-if="landing.sections.legalFooter.isPolicyLink" class="privacy-link" :href="getFullLink(landing.sections.legalFooter.Link)" 
                  target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                  {{ landing.sections.legalFooter.PolicyLabel }}
                </a>
                <a v-else class="privacy-link"  :style="{ color: landing.sections.legalFooter.LinkColor }" @click="openLegal('policy')">
                  {{ landing.sections.legalFooter.PolicyLabel }}
                </a>
                -
                <a v-if="landing.sections.legalFooter.isServiceLink" class="privacy-link" :href="getFullLink(landing.sections.legalFooter.ServiceLink)" 
                  target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                  {{ landing.sections.legalFooter.ServiceLabel }}
                </a>
                <a v-else class="privacy-link"  :style="{ color: landing.sections.legalFooter.LinkColor }" @click="openLegal('service')">
                  {{ landing.sections.legalFooter.ServiceLabel }}
                </a>
              </template>
              <template v-else>
                <a class="privacy-link" :href="getFullLink(landing.sections.legalFooter.Link)" target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                  {{ landing.sections.legalFooter.LinkLabel }}
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import draggable from 'vuedraggable'
import debounce from "debounce";
import VueScrollTo from 'vue-scrollto'

export default {
  components: {
    draggable,
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
    pageMode: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: '',
    },

    live: {
      type: Boolean,
      default: false,
    },

    token: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      pointsActive: true,
      rewardsActive: false,
      stats: {
        points: 0,
        ranking: 0,
        actions: {},
        rewards: 0,
        referrals: 0,
      }
    }
  },

  computed: {
    shareLink() {
      return `${process.env.VUE_APP_LP_DOMAIN}/l/${this.token}`
    },

    rewards() {
      if (!this.landing.rewards) return []

      return this.landing.rewards.filter(i => i.active)
    },

    combined: {
      get() {
        if (!this.landing.points && !this.landing.custom_urls) return []

        let exceptLinks = ['OptIn', 'ReferralOptIn']

        if (this.live && (window.innerWidth > 767 || (window.navigator && window.navigator.userAgentData && !window.navigator.userAgentData.mobile))) {
          exceptLinks.push('ShareText')
        }


        let points = Object.keys(this.landing.points).filter(c => !exceptLinks.includes(c) && this.landing.points[c].active)

        points = points.map(p => {
          let action = p.match(/Click/) ? 'Click ' : 'Share on '
          let link = this.landing.points[p].link || this.shareLink

          if (this.live) {
            if(typeof this.landing.points[p].link === 'undefined') {
              switch(p.replace(/(Click|Share)/, '')) {
                case 'Twitter':
                  link = `https://twitter.com/intent/tweet?text=${encodeURIComponent(link)}`
                  break;
                case 'Facebook':
                  link = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`
                  break;
                case 'Linkedin':
                  link = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`
                  break;
                case 'Pinterest':
                  link = `http://pinterest.com/pin/create/link/?url=${encodeURIComponent(link)}`
                  break;
                case 'Text':
                  let sms = this.landing.points[p].sms
                  sms = sms.replace(/\n/g, "%0a")
                  link = `sms://?&body=${sms}`
                  break;
                default:
                  break;
              }
            }
          }

          return {
            type: p,
            action: action + p.replace(/(Click|Share)/, ''),
            label: this.landing.points[p].label ? this.landing.points[p].label : action + p.replace(/(Click|Share)/, ''),
            points: this.landing.points[p].points,
            itemBackground: this.landing.points[p].itemBackground,
            itemColor: this.landing.points[p].itemColor,
            link: link,
            image: this.landing.points[p].image ? this.landing.points[p].image : null,
            social: p.replace(/(Click|Share)/, ''),
            order: this.landing.points[p].order
          }
        })

        let customUrls = [];

        if (this.landing.custom_urls)
        {
          customUrls = Object.keys(this.landing.custom_urls).filter(c => this.landing.custom_urls[c].active)
        }

        customUrls = customUrls.map(p => {
          let action = 'CustomSocial'
          let link = this.landing.custom_urls[p].link && this.landing.custom_urls[p].link.indexOf('http') === -1 ?  `https://${this.landing.custom_urls[p].link}` : this.landing.custom_urls[p].link 

          return {
            type: p,
            action: action + p.replace(/(CustomSocial)/, ''),
            label: this.landing.custom_urls[p].label ? this.landing.custom_urls[p].label : action + p.replace(/(CustomSocial)/, ''),
            points: this.landing.custom_urls[p].points,
            itemBackground: this.landing.custom_urls[p].itemBackground,
            itemColor: this.landing.custom_urls[p].itemColor,
            link: link,
            image: this.landing.custom_urls[p].image ? this.landing.custom_urls[p].image : null,
            order: this.landing.custom_urls[p].order
          }
        })

        let combined = points.concat(customUrls)

        combined.sort((a, b) => a.order < b.order ? -1 : 1)

        return combined
      },
      set(combined) {
        let orders = combined.map(p => p.type)

        this.$store.dispatch('landing/orderPoints', { landingId: this.landing.id, orders: orders })
          .then((landing) => {
            this.landing.points = landing.points
            this.landing.custom_urls = landing.custom_urls
            this.$emit('done', landing)
          })
          .catch(() => {})
      },
    },

    user() {
      return this.$store.getters['auth/user']
    },

    deviceContainerInner() {
      return {
        backgroundColor: this.landing.sections.styling.BackgroundColor,
      }
    },

    containerSectionStyle() {
      return {
        borderRadius: `${this.landing.loops.styling.Roudness}px`,
      }
    },

    logoContainerStyle() {
      return {
        textAlign: this.landing.sections.logo.Align,
        backgroundColor: this.landing.sections.logo.BackgroundColor,
        padding: `${this.landing.sections.logo.Padding}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    },

    legalContainerStyle() {
      return {
        margin: `10px 20px`,
        textAlign: this.landing.sections.legalFooter.Align,
        color: this.landing.sections.legalFooter.Color,
        fontSize: `${this.landing.sections.legalFooter.fontSize}px`,
      }
    },

    contentContainerStyle() {
      let style = {
        paddingBottom: '10px',
        overflow: 'hidden',
      }

      if (!this.landing.loops.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        style.borderRadius = `${this.landing.loops.styling.Roudness}px`
        if (this.selectedTab === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      }

      return style
    },

    landingWidgetStyle() {
      if (this.landing.loops.styling.Fullscreen) {
        return {

        }
      }

      return  {
        padding: '30px 10px',
      }
    },

    landingContainerStyle() {
      let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.landing.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
        width: '100%'
      }

      if (this.landing.loops.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        if (!this.landing.sections.logo.Show)
          style.justifyContent = 'center'
        if (this.selectedTab === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      } else {
        style.maxWidth = `${this.landing.loops.styling.MaxWidth}px`
      }
      

      return style
    },

    inputGroupStyle() {
      return {
        width: '100%',
        marginBottom: '15px',
      }
    },

    inputStyle() {
      return {
        width: '100%',
        padding: '12px 18px',
        fontSize: `${this.landing.sections.inputs.FontSize}px`,
        borderRadius: `${this.landing.sections.inputs.Roudness}px`,
        border: `1px solid ${this.landing.sections.inputs.BorderColor}`,
        backgroundColor: this.landing.sections.inputs.BackgroundColor,
        color: this.landing.sections.inputs.Color,
      }
    },

    mainContainerStyle() {
      if (!this.landing.loops.styling.Fullscreen) {
        return {
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: `${this.landing.sections.main.Padding}px`,
        }
      } else {
        return {
          maxWidth: `${this.landing.loops.styling.MaxWidth}px`,
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: '0px',
          margin: '0 auto' 
        }
      }
    },

    actionButtonStyle() {
      return {
        backgroundColor: this.landing.sections.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.sections.actionButton.BorderColor || 'transparent',
        color: this.landing.sections.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.sections.actionButton.Roudness}px`,
        fontSize: `${this.landing.sections.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.sections.actionButton.Padding}px`,
        width: '100%',
      }
    },

    videoType() {
      let link = this.landing.sections.styling.BackgroundVideo || ""
      if (link.match(/\/\/www\.youtube\.com/)) {
        return 'youtube'
      } else if(link.match(/\/\/vimeo.com/)) {
        return 'vimeo'
      }

      return 'video'
    },

    videoSource() {
      let link = this.landing.sections.styling.BackgroundVideo || ""

      if (this.videoType === 'youtube') {
        let videoId = link.replace(/.*\?v=/, '').replace(/&.*/, '')
        return `https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1`
      } else if(this.videoType === 'vimeo'){
        let videoId = link.replace(/.*com\//, '')
        return `https://player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;muted=1&amp;background=1`
      }

      return link.replace(/(https|http)/, 'https')
    },
  },

  watch: {
    landing: {
      handler(val, oldVal) {
        this.addCustomStyle()
      },
      deep: true,
    },

    token(val) {
      if (val) {
        this.getStats()
      }
    }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  mounted() {
    this.addCustomStyle()
     if (this.live) {
      this.getStats()
    }
  },

  methods: {
    handleScroll: debounce(function() {
      this.updateTabSelect();
    }, 100),

    selectTab(tab) {
      if (this.live) return;

      this.$emit('select-block', tab)
    },

    handleCopyLink() {
      this.$refs.shareLink.select()
      this.$refs.shareLink.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    handleClick(item) {
      if (!this.live) return;

      window.open(item.link)

      if(this.stats.actions[item.type]) return;
      
      this.$store.dispatch('landing/rewardAction', { token: this.token, action: `${item.type}` })
        .then(reward => {
          this.stats.points = reward.points
          this.stats.actions = reward.actions
        })
        .catch(() => {})
    },

    getFullLink(link) {
      if (link && !link.startsWith('http'))
      {
        return 'https://' + link
      }
      return link
    },

    selectBlock(block) {
      this.$emit('select-block', block)
    },

    getExportElement() {
      return this.$refs.graphicContainer
    },

    handleReceiveReward(reward) {
      if (this.stats.actions[`Reward${reward.id}`]) return;

      if (this.stats.points >= reward.points_unlock) {
        this.$store.dispatch('landing/receiveReward', { token: this.token, rewardId: reward.id })
          .then(rewards => {
            this.getStats()
          })
          .catch(() => {})
      }
    },

    getStats() {
      this.$store.dispatch('landing/rewardStats', this.token)
        .then(stats => {
          this.stats = stats
        })
        .catch(() => {})
    },

    addCustomStyle() {
      document.getElementById('landing-css') && document.getElementById('landing-css').remove()

      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'landing-css'
      style.innerHTML = `
        .landing-container .vti__dropdown {
          background-color: ${this.landing.sections.inputs.BackgroundColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
        }
        .landing-container .vti__input {
          padding: 12px 20px 12px 5px;
          height: initial;
          font-size: ${this.landing.sections.inputs.FontSize}px;
          color: ${this.landing.sections.inputs.Color};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }
        .landing-container .vue-tel-input {
          border-color: ${this.landing.sections.inputs.BorderColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }`
      document.querySelector('head').append(style)
    },

    goto(refName) {
      var element = this.$refs[refName];
      // var top = element.offsetTop;

      VueScrollTo.scrollTo(element, 200)
      
      if(refName === 'points-div') {
        this.pointsActive = true
        this.rewardsActive = false
      } else {
        this.pointsActive = false
        this.rewardsActive = true
      }
    },

    updateTabSelect: function () {
      var points = this.$refs['points-div'].offsetTop
      if(points < window.pageYOffset){
        this.pointsActive = false
        this.rewardsActive = true
      } else {
        this.pointsActive = true
        this.rewardsActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.live {
  .device-container  {
    border-radius: 0px;
    border: none;
    &:before, &:after {
      display: none;
    }
  }
}
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}

.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &.design {
    min-width: 90%;
  }
  &:before {
    content: " ";
    border: 2px solid #d9dfe6;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -9px;
    left: -10px;
    border-radius: 36px;
    z-index: -1;
  }
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.section-select {
  cursor: pointer;
  border: 1px solid transparent;
}
.device-container.design .section-select:hover {
  border-color: #4284f4;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

.list-points-section {
  display: flex;
  background: #FFF;
  text-align: center;
  .point-item {
    padding: 10px 0px;
    border-right: 2px solid #bcb8b8;
    width: 25%;
    &:last-child {
      border: none;
    }
    .point {
      font-size: 25px;
      font-weight: 700;
    }
    .desc {
      color: #8a8a8a;
    }
  }
}

.main-section {
  padding: 15px 0px;
}

.reward-section {
  display: flex;
  .get-points {
    padding: 20px;
    border-right: 2px solid #b3b0b0;
    width: 50%;
    .link {
      margin-bottom: 20px;
      display: flex;
      input {
        width: 100%;
        border: none;
        border-radius: 3px;
        box-shadow: 0px 0px 2px #ccc;
        padding: 5px 10px;
      }
      button {
        border: none;
        background: none;
        margin-left: 10px;
        background: #ed7d31;
        color: #FFF;
        border-radius: 5px;
      }
    }
    .list-actions {
      .action-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        font-weight: 600;
        padding: 12px 10px;
        margin-bottom: 5px;
        min-height: 55px;
        .point {
          float: right;
          background: #d9d9d9;
          padding: 7px 10px;
          color: #414141;
          font-weight: 700;
          border-radius: 5px;
          width: 36px;
          text-align: center;
        }
      }
    }
  }
  .rewards {
    width: 50%;
    padding: 20px 15px;
    .list-rewards {
      .reward-item {
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        margin-bottom: 25px;
        position: relative;
        .unlock {
          background: #548235;
          padding: 5px;
          border-radius: 5px;
          text-align: center;
          font-weight: 600;
        }
        .content {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 16px;
        }
        .icon {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: absolute;
          top: -20px;
          left: calc(50% - 20px);
          font-size: 25px;
          line-height: 40px;
          cursor: pointer;
        }
      }
      .progress-container {
        background: #d9d9d9;
        margin-top: 10px;
        border-radius: 10px;
        height: 14px;
        position: relative;
        overflow: hidden;
        .progress-completed {
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: #1ba614;
          border-radius: 10px 0px 0px 10px;
        }
      }
      .progress-stat {
        font-size: 12px;
        text-align: right;
        color: #FFF;
        margin-top: 2px;
      }
    }
  }
  h3 {
    border-bottom: 1px solid #b3b0b0;
    padding-bottom: 10px;
  }
}

.points-div, .rewards-div{
  h3 {
    border-bottom: 1px solid #b3b0b0;
    padding-bottom: 10px;
  }
}

.points-div {
  border-right : 2px solid #b3b0b0;
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .reward-section{
    display:block;
    .get-points {
      width: 100%;
    }
    .rewards {
      width: 100%;
    }

  }

  .points-div, .rewards-div{
    text-align: center;
    border-bottom: 1px solid #b3b0b0;
    padding-top: 7px;
     h3 {
        border-bottom: 0px solid #b3b0b0;
      }
  }

  .selectedDiv {
    background-color: #478c41;
     h3 {
       color: white;
     }
  }

  .unselectedDiv {
     h3 {
       color: black;
     }
  }
  
}
</style>
